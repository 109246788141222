export const isAutoPlayingKey = 'isAutoPlaying'

export const shouldAutoPlay = (autostart?: boolean) => {
  if (!autostart) return false

  const isAutoPlaying = sessionStorage.getItem(isAutoPlayingKey) === 'true'

  sessionStorage.setItem(isAutoPlayingKey, 'true')

  return !isAutoPlaying
}
