import React from 'react'
import { GetServerSideProps, GetServerSidePropsResult, NextPage } from 'next'
import Video from '../src/components/Video'
import { TPageProps } from '../typings/pageProps'
import { noticeError, trackView } from '../src/utils'

const Home: NextPage<TPageProps> = (pageProps: TPageProps) => {
  React.useEffect(() => {
    try {
      trackView({ media_id: pageProps.videoIds?.join(',') || null })
    } catch (error) {
      console.error(error)
      noticeError('trackView failed: ' + (error as any).message)
    }
  }, [])
  return <Video {...pageProps} />
}

export const getServerSideProps: GetServerSideProps = async ({
  res,
  req,
  query,
}): Promise<GetServerSidePropsResult<TPageProps>> => {
  const { video, token } = query

  if (res) {
    res.setHeader('Cache-Control', `max-age=${process.env.CACHE_DURATION || '86400'}`)
  }
  return {
    props: {
      videoIds: (typeof video === 'string' ? video.split(',') : video) || [],
      token: (Array.isArray(token) ? token[0] : token) || null,
      playerConfigUrl: process.env.PLAYER_CONFIG_URL as string,
      tealiumEnv: process.env.TEALIUM_ENV as string,
      baitUrl: process.env.BAIT_URL as string,
      dataFileUrl: process.env.DATA_FILE_URL as string,
      ipDataURL: process.env.IP_DATA_URL as string,
      countryCode: `${req.headers['cloudfront-viewer-country'] || 'NL'}`,
      env: process.env.ENV || 'prod',
      oneTrustId: process.env.ONE_TRUST_ID || '',
      convivaConfig: { apiKey: process.env.CONVIVA_API_KEY || '' },
    },
  }
}

export default Home
