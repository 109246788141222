import React, { useState, useEffect, Fragment, FunctionComponent } from 'react'
import Player from '@4tn/tv-npm-player'
import LoaderIcon from '../LoaderIcon'
import { TPlayerConfigReact } from '../../../typings/playerConfig'
import { uuid } from '../../utils/uuid'
import { shouldAutoPlay } from 'src/utils/shouldAutoPlay'
import { useClearSessionStorage } from 'src/hooks/useClearSessionStorage'

type TProps = {
  config: TPlayerConfigReact
  initCallback?: (player: Player) => void
}

const PlayerComponent: FunctionComponent<TProps> = (props: TProps): JSX.Element => {
  const { config, initCallback } = props
  const [containerId] = useState(`player-${uuid()}`)

  
  useClearSessionStorage();

  useEffect(() => {
    new Player(
      {
      ...config,
      playbackOptions: {
        ...config.playbackOptions,
        autostart: shouldAutoPlay(config?.playbackOptions?.autostart as boolean | undefined),
      },
      containerId,
      },
      initCallback,
    )
  }, [containerId])

  return (
    <Fragment>
      <div className="player" id={containerId}>
        <LoaderIcon />
      </div>
    </Fragment>
  )
}

export default PlayerComponent
