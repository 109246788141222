import React, { useState } from 'react'
import Player, { Program } from '@4tn/tv-npm-player'
import PlayerComponent from '../PlayerComponent'
import { useRouter } from 'next/router'
import { TPageProps } from '../../../typings'
import {
  referrer,
  inIframe,
  preloadImage,
  isClient,
  additionalAttributes,
  onErrorOrWarning,
  setAdditionalAttributes,
  validateTcf,
  probeDrmSupport,
  styleError,
  detectAdBlock,
  customInteraction,
  noticeError,
  preventScrubbingDuringAds,
  isOnTalpaDomain,
  addConsentButton,
  isEmbedded,
} from '../../utils'
import featureTooling, { FEATURES } from '../../utils/featureTooling'
import { TPlayerConfigReact } from '../../../typings/playerConfig'
import HeadWrapper from '../HeadWrapper'
import SetupErrorMessage from '../SetupErrorMessage'
import VideoAdBlockEnabled from '../VideoAdBlockEnabled'
import getClientIP from '../../utils/getClientIP'
import { createProxy } from '../../utils/jwProxy'
import { RESTRICTED_VIDEO_ERROR } from '../../constants'

const Video: React.FC<TPageProps> = ({
  videoIds,
  token,
  consentData,
  baitUrl,
  playerConfigUrl,
  ipDataURL,
  countryCode,
  convivaConfig,
}) => {
  const {
    query: { autostart, mute, start, stop, shareLink, autoplay },
  } = useRouter()
  const [setupError, setSetupError] = useState<string>()
  const [activeVideoItem, setActiveVideoItem] = useState<Program>()
  const [adBlockerDetected, setAdBlockDetected] = useState<boolean>()
  const adBlockDetectionPending = adBlockerDetected === undefined

  React.useEffect(() => {
    if (!featureTooling.isFeatureEnabled(FEATURES.ADBLOCK_DETECTION)) {
      return setAdBlockDetected(false)
    }

    detectAdBlock(baitUrl).then(setAdBlockDetected)
  }, [])

  React.useEffect(() => {
    probeDrmSupport().then(async ({ drmSupported }) => {
      const attributes: {
        [key: string]: string | boolean | null
      } = {
        drmSupported,
        token,
        referrer,
        iFrame: inIframe,
        isEmbedded,
        userAgent: navigator.userAgent,
        media_id: videoIds.join(','),
        talpaConsent: consentData?.hasTalpaConsent || false,
        consentAvailable: !!consentData?.tcString,
      }
      if (featureTooling.isFeatureEnabled(FEATURES.TRACK_IP)) {
        attributes.clientIP = await getClientIP(ipDataURL)
      }

      setAdditionalAttributes(attributes)
      customInteraction('Player Ready')
    })
  }, [])

  const config: TPlayerConfigReact = {
    playerConfigUrl,
    playlist: videoIds,
    playbackOptions: {
      width: '100%',
      height: '100%',
      aspectratio: '',
      autostart: autoplay === 'true' || autoplay === '1' || autostart === 'true' || autostart === '1',
      mute: mute === 'true' || mute === '1',
      start: start && !isNaN(+start) ? +start : undefined,
      stop: stop && !isNaN(+stop) ? +stop : undefined,
      controls: true,
    },
    countryCode,
    consent: consentData,
    shareLink: shareLink && `${shareLink}`,
    ...(referrer && { referrer }),
    ...(token && { token }),
    chromecastReceiverId: featureTooling.getFeatureVariable<string>(FEATURES.CASTING_EMBED, 'appId') || '',
    fullEpisodeButton: {
      watchEpisodeOnDomain: featureTooling.isFeatureEnabled(FEATURES.FULL_EPISODE_BUTTON_ON_DOMAIN),
      enabled: featureTooling.isFeatureEnabled(FEATURES.FULL_EPISODE_BUTTON),
    },
    adobeDelay: featureTooling.isFeatureEnabled(FEATURES.ADOBE_DELAY),
    convivaConfig,
  }

  const playerInitCallback = async (player: Player): Promise<void> => {
    const { playlist, jwplayer, error, playerConfig } = player || {}

    if (playlist[0]) {
      preloadImage(playlist[0].image)
      setActiveVideoItem(playlist[0])
    }

    if (!playlist.length || error) {
      setSetupError(error.group)
      if (typeof window.newrelic === 'object') {
        window.newrelic.noticeError(new Error(error?.message || 'Player initialization error'), {
          group: error?.group || 'Player initialization error group',
          ...additionalAttributes,
        })
      }
      return
    }

    if (playerConfig.advertising?.freewheel.sectionid) {
      setAdditionalAttributes({ sectionid: playerConfig.advertising.freewheel.sectionid })
    }

    jwplayer.on('playlistItem', (event: { item: Program }) => {
      const { item } = event

      const [source] = item?.sources || []

      setAdditionalAttributes({
        media_id: item.guid,
        source_type: source.type || null,
        drm_type: (source.drm && Object.keys(source.drm)[0]) || null,
      })

      setActiveVideoItem(item)

      const restrictedSeries = featureTooling.getFeatureVariables<string[]>(FEATURES.TALPA_RESTRICTED_SERIES) || []
      const [, seriesSlug] = item.kijkLink.match(/\/programmas\/([\w-]+)/) || []
      if (!isOnTalpaDomain && restrictedSeries.includes(seriesSlug)) {
        jwplayer.remove()
        setSetupError(RESTRICTED_VIDEO_ERROR)
      }

      if (featureTooling.isFeatureEnabled(FEATURES.BLOCK_AD_SCRUB)) {
        preventScrubbingDuringAds(jwplayer)
      }

      if (featureTooling.isFeatureEnabled(FEATURES.TFC_APP_VALIDATION)) {
        validateTcf()
      }
    })

    jwplayer.on('warning', onErrorOrWarning)
    jwplayer.on('error', (event: jwplayer.ErrorParam) => {
      onErrorOrWarning(event)

      const playlistItem = (window as any).jwplayer().getPlaylistItem()
      setTimeout(styleError, 0, {
        error: event,
        item: playlistItem,
        drmSupported: true,
      })
    })

    jwplayer.on('adComplete', () => {
      customInteraction('Ad watched')
    })

    jwplayer.on('adError', () => {
      customInteraction('Failed Ad')
    })

    jwplayer.on('adBreakStart' as keyof jwplayer.EventParams, () => {
      setAdditionalAttributes({
        adPlaying: true,
      })
    })

    jwplayer.on('adBreakEnd' as keyof jwplayer.EventParams, () => {
      setAdditionalAttributes({
        adPlaying: null,
      })
    })

    jwplayer.on('setupError', async (event: any) => {
      const { drmSupported } = await probeDrmSupport()
      onErrorOrWarning(event)
      if (!drmSupported) {
        styleError({ error: event, item: playlist[0], drmSupported })
      }
    })

    jwplayer.on('firstFrame', async () => {
      customInteraction('Stream start')
      const item = jwplayer.getPlaylistItem()
      if (item.metadata.media_duration === null) {
        noticeError('Missing duration')
      }
    })

    jwplayer.on('beforePlay', () => {
      addConsentButton(jwplayer)
    })

    createProxy(jwplayer)
  }

  // Wait for AdBlock detection to complete
  if (adBlockDetectionPending) {
    return null
  }

  if (setupError || !videoIds.length) {
    return <SetupErrorMessage videoItem={activeVideoItem} errorGroup={setupError || 'Missing video'} />
  }

  if (adBlockerDetected) {
    return (
      <VideoAdBlockEnabled
        videoItem={activeVideoItem}
        videoIds={videoIds}
        handleAdBlockChange={setAdBlockDetected}
        baitUrl={baitUrl}
      />
    )
  }

  return (
    <React.Fragment>
      <HeadWrapper video={activeVideoItem} videoIds={videoIds} />
      {isClient ? <PlayerComponent config={config} initCallback={playerInitCallback} /> : null}
    </React.Fragment>
  )
}

export default Video
