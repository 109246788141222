import { useEffect } from 'react'

export const useClearSessionStorage = () => {
  useEffect(() => {
    const clearSessionStorage = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = true // Included for legacy support, e.g. Chrome/Edge < 119
      sessionStorage.clear()
    }

    window.addEventListener('beforeunload', clearSessionStorage)

    return () => {
      window.removeEventListener('beforeunload', clearSessionStorage)
    }
  }, [])
}
